import React from 'react';

function NotFound() {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-20 w-auto"
                    src="/logo.png"
                    alt="Payd"
                />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    404
                </h2>
            </div>
            <div className="text-center">
                Page Not Found
            </div>
        </div>
    );
}

export default NotFound;