import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from "react-query";
import StatusBadge from "../../../components/StatusBadge";
import ButtonLink from "../../../components/ButtonLink";
import Loading from "../../../components/Loading";
import {CANCEL_PAYMENT, GET_PAYMENT_DETAILS, GET_PAYMENT_TRANSACTIONS} from "../../../utils/graphql";
import {apollo} from "../../../utils/apollo";
import InformationLine from "../../../components/InformationLine";
import ProductsDetails from "../../../components/ProductsDetails";
import MoneyHighlight from "../../../components/MoneyHighlight";
import {FlagIcon} from "@heroicons/react/24/solid";
import Button from "../../../components/Button";
import InvoiceField from "../../../components/InvoiceField";
import TransactionList from "../../../TransactionList";

function PaymentView() {
    const {payment_uuid} = useParams();

    const fetchPayment = useQuery({
        queryKey: 'payment_view', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_PAYMENT_DETAILS, variables: {
                    paymentUuid: payment_uuid,
                },
            });
            return data?.getPaymentDetails;
        }, keepPreviousData: false
    });

    const fetchPaymentTransactions = useQuery({
        queryKey: 'payment_transactions_view', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_PAYMENT_TRANSACTIONS, variables: {
                    paymentUuid: payment_uuid,
                },
            });
            return data?.getPaymentTransactions;
        }, keepPreviousData: false
    });

    const cancelPaymentMutation = useMutation({
        mutationKey: 'cancel_payment', mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CANCEL_PAYMENT, variables: {
                    paymentUuid: payment_uuid
                },
            });
            return data?.cancelPayment;
        }, onSuccess: (canceled) => {
            if (canceled === true) {
                fetchPayment.refetch();
            }
        }
    })

    const payment = useMemo(() => fetchPayment?.isSuccess ? fetchPayment?.data : null, [fetchPayment]);
    const transactions = useMemo(() => fetchPaymentTransactions?.isSuccess ? fetchPaymentTransactions?.data : null, [fetchPaymentTransactions]);

    return (<DashboardLayout>
        <PageHeader back="/merchant/payments" title="Payment View"
                    description="This page provides you with the capability to generate new payment links for seamless transactions."/>

        {(fetchPayment.isLoading) && <Loading/>}
        {(fetchPayment.isSuccess && payment) && <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
                <InformationLine title="Payment Uuid" value={payment?.paymentUuid}/>
                <ProductsDetails payment={payment}/>
                <InformationLine title="Total Amount" value={<MoneyHighlight value={payment?.amount}
                                                                             currency={payment?.currency?.symbol}/>}/>
                <InformationLine title="Status" value={<StatusBadge status={payment?.status}/>}/>

                <div className="pt-4">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Customer Details</dt>
                    <div className="grid grid-cols-1 sm:grid-cols-12 gap-2 sm:gap-4 my-3">
                        <InvoiceField name="Name" value={payment?.name}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                        <InvoiceField name="Email" value={payment?.email}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                        <InvoiceField name="Phone" value={payment?.phone}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                        <InvoiceField name="Address" value={payment?.address}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                        <InvoiceField name="City" value={payment?.city}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                        <InvoiceField name="Zip Code" value={payment?.zipCode}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                        <InvoiceField name="Country" value={payment?.country}
                                      className="col-span-12 sm:col-span-4 mt-1 text-sm leading-6 text-gray-700 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg"/>
                    </div>
                </div>

                <InformationLine title="Gateway Reference Number" value={payment?.gatewayReferenceNumber}/>
                <InformationLine title="Customer Reference Number" value={payment?.customerReferenceNumber}/>
                <InformationLine title="Auth Code" value={payment?.authCode}/>
                <InformationLine title="Anti Fraud"
                                 value={payment?.antiFraud && <FlagIcon className="h-5 w-5 text-rose-500"/>}/>
                <InformationLine title="Card Number" value={payment?.cardNumber}/>
                <InformationLine title="Created At" value={payment?.createdAt}/>
                <InformationLine title="Expires At" value={payment?.expiresAt}/>
                <InformationLine title="Settlement Uuid" value={payment?.settlement?.settlementUuid}/>
                {/*http://payd.local/merchant/settlements/view/ST069815700191558*/}
                <InformationLine title="Remarks" value={payment?.remarks}/>

                <div className="flex my-6 gap-4 items-center">
                    <ButtonLink href={`/pay/${payment?.paymentUuid}`}>Payment Link</ButtonLink>
                    {['pending', 'failed'].includes(payment?.status) &&
                        <Button secondary={true} onClick={() => cancelPaymentMutation.mutate()}
                                disabled={cancelPaymentMutation.isLoading}>Cancel Payment</Button>}
                    {payment?.test ? <span
                            className="bg-clip-text print:text-black text-transparent bg-gradient-to-r from-red-500 to-orange-500 font-bold text-md">Test Mode</span> :
                        <span
                            className="bg-clip-text print:text-black text-transparent bg-gradient-to-r from-green-500 to-blue-500 font-bold text-md">Live Mode</span>}
                </div>

                {fetchPaymentTransactions.isLoading && <Loading/>}
                {fetchPaymentTransactions.isSuccess && !!transactions?.length &&
                    <TransactionList transactions={transactions}/>}

            </dl>
        </div>}

    </DashboardLayout>);
}

export default PaymentView;



