import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {FunnelIcon, QueueListIcon} from "@heroicons/react/24/outline";
import IconButton from "../../../components/IconButton";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import ButtonInternalLink from "../../../components/ButtonInternalLink";
import SelectInput from "../../../components/SelectInput";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_PAYMENT_LIST, GET_PAYMENT_STATUS_COUNT} from "../../../utils/graphql";
import PaymentLinkDialog from "../../../components/PaymentLinkDialog";
import PaymentReportGenerateDialog from "../../../components/PaymentReportGenerateDialog";
import Button from "../../../components/Button";
import Tabs from "../../../components/Tabs";
import {PAYMENT_LIST_STATUS_FOR_FILTER} from "../../../utils/constants";
import SimplePaymentList from "./components/SimplePaymentList";
import CompactPaymentList from "./components/CompactPaymentList";
import usePreferenceStore from "../../../store/preference";
import PaginationFull from "../../../components/PaginationFull";
import TextInput from "../../../components/TextInput";

function Payment() {
    const {t} = useTranslation();
    const [sort, setSort] = useState({field: 'id', isAscending: false});
    const [page, setPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState({status: '', test: '', paymentUuid: ''})
    const [statusTabs, setStatusTabs] = useState(PAYMENT_LIST_STATUS_FOR_FILTER)
    const [linkToShow, setLinkToShow] = useState(null);
    const [showPaymentReportDialog, setShowPaymentReportDialog] = useState(false);
    const {paymentListStyle, togglePaymentListStyle} = usePreferenceStore();
    useEffect(() => setPage(1), [filter, sort]);

    useQuery('payment_status_count', async () => {
        const {data} = await apollo.query({
            query: GET_PAYMENT_STATUS_COUNT, variables: {},
        });
        return data?.getPaymentStatusCount;
    }, {
        keepPreviousData: true, onSettled: (data) => {
            if (data && Array.isArray(data)) {
                let updatedStatusTabs = [...statusTabs];
                data.forEach((item) => {
                    let foundIndex = updatedStatusTabs.findIndex(tab => tab.column === item.status);
                    if (foundIndex !== -1) {
                        updatedStatusTabs[foundIndex].count = parseInt(item.count);
                    }
                });
                setStatusTabs(updatedStatusTabs);
            }
        },
    });

    const fetchPaymentLinks = useQuery(['payment_links', {sort, page, filter}], async () => {
        const {data} = await apollo.query({
            query: GET_PAYMENT_LIST, variables: {
                sortBy: sort.field?.toUpperCase(),
                sortDirection: sort.isAscending ? "ASC" : "DESC",
                page,
                first: 15,
                filter: {
                    status: filter.status,
                    test: filter.test,
                    paymentUuid: filter.paymentUuid,
                }
            },
        });
        return data?.getPaymentList || null;
    }, {keepPreviousData: false, refetchInterval: 10000},);

    const records = fetchPaymentLinks?.data || null;

    const handleChangeSort = (field) => {
        if (sort.field === field) {
            setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
        } else {
            setSort({field: field, isAscending: true});
        }
    };

    return (<DashboardLayout>
        <PageHeader title={t('payment.page_title')} description={t('payment.page_description')}>
            <div className="flex items-center gap-3 flex-wrap">
                <IconButton onClick={() => setShowFilter(!showFilter)}>
                    <FunnelIcon className="w-5 h-5 text-blue-500"/>
                </IconButton>
                <IconButton onClick={togglePaymentListStyle}>
                    <QueueListIcon className="w-5 h-5 text-blue-500"/>
                </IconButton>
                <div className="flex items-center gap-3 flex-wrap">
                    <ButtonInternalLink
                        secondary={true}
                        href="/merchant/payments/create-bulk">{t('payment.create_bulk_payments')}</ButtonInternalLink>
                    <Button
                        secondary={true}
                        onClick={() => setShowPaymentReportDialog(true)}>{t('payment.download_reports')}</Button>
                    <ButtonInternalLink
                        href="/merchant/payments/create">{t('payment.create_payment_link')}</ButtonInternalLink>
                </div>
            </div>
        </PageHeader>
        <PaymentLinkDialog get={linkToShow} set={setLinkToShow}/>
        <PaymentReportGenerateDialog active={showPaymentReportDialog} toggle={setShowPaymentReportDialog}
                                     filter={filter}/>
        {showFilter &&
            <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-3">
                    <TextInput name="status-filter" label="Invoice Id / Reference" id="status-filter"
                               get={filter.paymentUuid}
                               set={v => setFilter({...filter, paymentUuid: v})}/>
                </div>
                <div className="col-span-3">
                    <SelectInput name="status-filter" label="Status" id="status-filter" get={filter.status}
                                 set={v => setFilter({...filter, status: v})}>
                        {PAYMENT_LIST_STATUS_FOR_FILTER.map(s => <option key={s.column}
                                                                         value={s.column}>{s.name} {s.count ? `(${s.count})` : ''}</option>)}
                    </SelectInput>
                </div>
                <div className="col-span-3">
                    <SelectInput name="test-filter" label="Payment Environment" id="test-filter" get={filter.test}
                                 set={v => setFilter({...filter, test: v})}>
                        <option value="">All</option>
                        <option value="true">Test Mode</option>
                        <option value="false">Live Mode</option>
                    </SelectInput>
                </div>
            </div>}
        <Tabs tabs={statusTabs} get={filter?.status} set={v => setFilter({...filter, status: v})}/>
        {fetchPaymentLinks.isLoading && <Loading/>}
        {(fetchPaymentLinks.isSuccess && records) && <>
            {paymentListStyle === 'simple' && <SimplePaymentList handleChangeSort={handleChangeSort}
                                                                 records={records}
                                                                 sort={sort}
                                                                 setLinkToShow={setLinkToShow}
            />}

            {paymentListStyle === 'compact' && <CompactPaymentList handleChangeSort={handleChangeSort}
                                                                   records={records}
                                                                   sort={sort}
                                                                   setLinkToShow={setLinkToShow}
            />}
            {/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
            <PaginationFull paginator={records?.paginatorInfo} get={page} set={setPage}/>

        </>}
    </DashboardLayout>);
}


export default Payment;

